import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  'demo-wrap': {
    padding: '88px 0 58px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-start',
    backgroundImage: `url(${require(`@/images/img/img_bg_our_game.webp`).default})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '155px 0 96px',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '8% 18.75% 8% 5.8%',
    },
  },
  'demo-image': {
    flex: '1',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '53.65%',
      '& img': {
        position: 'absolute',
        // top: '-152px',
        bottom: '27.3%',
        left: '4.87%',
        width: '104.19%',
      },
    },
  },
  'demo-container': {
    position: 'relative',
    zIndex: '1',
    padding: '0 5.55%',
    [theme.breakpoints.up('sm')]: {
      padding: '0 10.42%',
    },
    [theme.breakpoints.up('md')]: {
      width: '411px',
      padding: '0',
    },
  },
  'demo-title': {
    fontFamily: 'Oswald, sans-serif',
    fontSize: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '16px',
    lineHeight: 'normal',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: '48px',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  'demo-content': {
    width: '100%',
    margin: 'auto',
    marginBottom: '32px',
    fontSize: '16px',
    fontFamily: 'Noto Sans',
    lineHeight: '140%',
    letterSpacing: '0.54px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '48px',
      fontSize: '19px',
      letterSpacing: '0.72px',
    },
  },
  'demo-link-container': {
    borderRadius: '8px',
    background: 'linear-gradient(179deg, #EFBD3C 1.1%, #BE8C0D 98.58%)',
    width: '100%',
    padding: '2px 3px 4px',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: '382px',
      margin: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      width: '263px',
      margin: 'initial',
    },
  },
  'demo-link-item': {
    width: '100%',
    height: '35px',
    lineHeight: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    textAlign: 'center',
    fontFamily: 'Oswald, sans-serif',
    fontSize: '16px',
    fontWeight: '700',
    textDecoration: 'none',
    borderRadius: '8px',
    textTransform: 'uppercase',
    borderRadius: '4px',
    border: '1px solid #FFE86E',
    background: 'linear-gradient(178deg, #E2B61C 1.23%, #ECA71D 98.31%)',
    // backgroundImage: `url(${require(`@/images/img/demo_link_bg.webp`).default})`,
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset, 0px 2px 0px 0px #87621B',
    position: 'relative',
    '& span': {
      textShadow: '0px 2px 2px #8E6617',
      zIndex: '1',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '0',
      width: '100%',
      height: '52px',
      transform: 'translateY(-50%)',
      borderRadius: '4px',
      background: 'linear-gradient(180deg, rgba(248, 212, 100, 0.00) 0%, #FFE698 46.5%, rgba(245, 202, 64, 0.00) 100%)',
      mixBlendMode: 'soft-light',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '50%',
      width: '66px',
      height: '4px',
      transform: 'translateX(-50%)',
      background: 'linear-gradient(90deg, #FCFF6C 0%, #FBFF20 100%)',
      filter: 'blur(7px) drop-shadow(0px 0px 4px #FFF)',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
      height: '52px',
      lineHeight: '52px',
    },
  },
  'demo-link-item-icon': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '& svg': {
      width: 'auto',
      height: '30px',
      [theme.breakpoints.up('sm')]: {
        height: '45px',
      },
    },
  },
  'demo-link-item-icon-left': {
    animation: '$slide-left 1s infinite',
  },
  '@keyframes slide-left': {
    from: {
      transform: 'translateX(0)',
    },
    to: {
      transform: 'translateX(8px)',
    },
  },
  'demo-link-item-icon-right': {
    animation: '$slide-right 1s infinite',
  },
  '@keyframes slide-right': {
    from: {
      transform: 'translateX(0)',
    },
    to: {
      transform: 'translateX(-8px)',
    },
  },
  'demo-link-bg': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    backgroundImage: `url(${require(`@/images/img/demo_link_bg.webp`).default})`,
    mixBlendMode: 'soft-light',
    zIndex: '0',
  },
}));
