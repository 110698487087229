import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  'contact-wrap': {
    padding: '88px 20px 178px',
    // marginTop: '-5.54%',
    position: 'relative',
    backgroundImage: `url(${require(`@/images/img/img_bg_contact.webp`).default})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '156px 0 469px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '9% 0 19%',
    },
  },
  'contact-container': {
    position: 'relative',
    zIndex: '1',
  },
  'contact-title': {
    fontFamily: 'Oswald, sans-serif',
    fontSize: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '16px',
    textTransform: 'uppercase',
    position: 'relative',
    zIndex: '1',
    lineHeight: 'normal',
    [theme.breakpoints.up('sm')]: {
      fontSize: '48px',
    },
  },
  'contact-content': {
    width: '100%',
    maxWidth: '100%',
    margin: 'auto',
    marginBottom: '24px',
    fontSize: '16px',
    fontFamily: 'Noto Sans',
    lineHeight: '140%',
    letterSpacing: '0.72px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      maxWidth: '608px',
      marginBottom: '64px',
      letterSpacing: '0.8px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '960px',
      '&.cn': {
        textAlign: 'center',
      },
    },
  },
  'contact-form-group': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  qrcode: {
    display: 'block',
    width: '160px',
    height: '176px',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: '200px',
      height: '220px',
    },
  },
  'icon-click': {
    width: '88px',
    position: 'absolute',
    left: '102%',
    top: '-25px',
    animation: '$pulse 1s infinite',
    [theme.breakpoints.up('sm')]: {
      width: '120px',
      left: 'calc(100% + 12px)',
    },
  },
  '@keyframes pulse': {
    '10%': { transform: 'scale(1.1)' },
  },
  'tg-account': {
    position: 'absolute',
    bottom: '12px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontFamily: 'Noto Sans',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '27.24px',
    color: '#2A2A2A',
    [theme.breakpoints.up('sm')]: {
      bottom: '17px',
      fontSize: '24px',
    },
  },
}));
