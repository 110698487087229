import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames/bind';
import Mask from '@/component/mask/Mask';
import { navbarItems } from '@/enum/common';
import { scrollToSection } from '@/utils';
import { useStyles } from './gamingStyle';

export default function Gaming() {
  const { t } = useTranslation();
  const cx = cn.bind(useStyles());
  const [wrapPaddingBottom, setWrapPaddingBottom] = useState(window.innerHeight > 1080 ? 1080 : window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWrapPaddingBottom(window.innerHeight > 1080 ? 1080 : window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    /* Gaming Section */
    <section className={cx('gaming-wrap')} id={navbarItems[0]} style={{ paddingBottom: wrapPaddingBottom }}>
      <div className={cx('gaming-container')}>
        <div className={cx('gaming-text-group', 'animate-fade-in')}>
          <p className={cx('gaming-placeholder')}>{t('become_master')}</p>
          <h1 className={cx('gaming-title')}>{t('cockfighting_gaming')}</h1>
        </div>
      </div>
      <Mask goToSection={() => scrollToSection(navbarItems[1])} />
    </section>
  );
}
