import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames/bind';
import MaskTop from '@/component/mask-top/MaskTop';
import { navbarItems } from '@/enum/common';
import { useStyles } from './contactStyle';

export default function Home() {
  const { t, i18n } = useTranslation();
  const cx = cn.bind(useStyles());

  return (
    /* Contact Section */
    <section id={navbarItems[4]} className={cx('contact-wrap')}>
      <MaskTop />
      <div className={cx('contact-container')} id={`${navbarItems[4]}-container`}>
        <h2 data-aos='fade-up' className={cx('contact-title', 'animate-fade-in-position')}>
          {t('title.contact')}
        </h2>
        <p data-aos='fade-up' className={cx('contact-content', 'animate-fade-in-position', i18n.language)}>
          {t('contact_content')}
        </p>
        <div className={cx('contact-form-group')}>
          <a
            href='https://t.me/GameR0R1'
            target='_blank'
            data-aos='fade-up'
            className={cx('qrcode', 'animate-fade-in-position')}>
            <img src={require('@/images/img/QRcode.webp').default} alt='qrcode' />
            <span className={cx('tg-account')}>@GameR0R1</span>
            <img src={require('@/images/img/icon_click.webp').default} alt='click icon' className={cx('icon-click')} />
          </a>
        </div>
      </div>
    </section>
  );
}
