import { createTheme } from '@material-ui/core/styles';

const breakpoints = {
  values: {
    xs: 0,
    sm: 768,
    md: 1025,
    lg: 1280,
    xl: 1920,
  },
};

const basicFontSize = 12;

const baseTheme = {
  breakpoints,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          scrollBehavior: 'smooth',
          height: '100%',
          // eslint-disable-next-line no-dupe-keys
          height: 'fill-available',
        },
        body: {
          height: 'inherit',
          fontFamily: "'Noto Sans', sans-serif, Arial",
          // visibility: 'hidden',
        },
        '#root': {
          height: 'inherit',
        },
        '*': {
          boxSizing: 'border-box',
          color: 'var(--white)',
          margin: '0',
          padding: '0',
          scrollbarColor: `${'var(--background-lighter)'} ${'var(--background-deeper)'}`,
        },
        img: {
          width: '100%',
          verticalAlign: 'top',
        },
        svg: {
          width: '100%',
          height: '100%',
        },
      },
    },
  },
};

export const theme = createTheme(baseTheme, {
  themeParams: {
    rootFontPXSize: basicFontSize,
  },
});
