import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames/bind';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MaskTop from '@/component/mask-top/MaskTop';
import Mask from '@/component/mask/Mask';
import { theme } from '@/config/theme';
import { navbarItems, demoLinks, languages } from '@/enum/common';
import { scrollToSection } from '@/utils';
import { useStyles } from './demoStyle';

export default function Home() {
  const { t, i18n } = useTranslation();
  const cx = cn.bind(useStyles());
  const [isMobile, setIsMobile] = useState(window.innerWidth <= theme.breakpoints.values['md']);

  useEffect(() => {
    setIsMobile(window.innerWidth <= theme.breakpoints.values['md']);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= theme.breakpoints.values['md']);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const currentDemo = useMemo(() => {
    const currentLangIndex = languages.findIndex((item) => item.value === i18n.language);
    return currentLangIndex === -1 ? undefined : demoLinks[currentLangIndex];
  }, [i18n.language]);

  const containerId = useMemo(() => (isMobile ? `${navbarItems[3]}-container` : ''), [isMobile]);

  return (
    /* Demo Section */
    <section className={cx('demo-wrap')} id={navbarItems[3]}>
      <MaskTop />
      <div data-aos='fade-right' className={cx('demo-image', 'animate-fade-right-position')}>
        <img src={require('@/images/img/img_our_game_cock.webp').default} alt='demo cockfight' />
      </div>
      <div className={cx('demo-container')} id={containerId}>
        <h2 data-aos='fade-up' className={cx('demo-title', 'animate-fade-in-position')}>
          {t('title.demo')}
        </h2>
        <p data-aos='fade-up' className={cx('demo-content', 'animate-fade-in-position')}>
          {t('demo_content')}
        </p>
        <div data-aos='fade-up' className={cx('demo-link-container', 'animate-fade-in-position')}>
          {currentDemo && (
            <a href={currentDemo} target='_blank' rel='noreferrer' className={cx('demo-link-item')}>
              <span className={cx('demo-link-item-icon', 'demo-link-item-icon-left')}>
                <ArrowRightIcon />
              </span>
              <span>{t('demo_link')}</span>
              <span className={cx('demo-link-item-icon', 'demo-link-item-icon-right')}>
                <ArrowLeftIcon />
              </span>
              <span className={cx('demo-link-bg')} />
            </a>
          )}
        </div>
      </div>
      <Mask goToSection={() => scrollToSection(navbarItems[4])} />
    </section>
  );
}
