import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    position: 'sticky',
    top: '0',
    zIndex: '10',
    padding: '4px 0',
    background: 'linear-gradient(180deg, #000 0%, transparent 100%)',
  },
  'header-active': {
    transition: 'background 0.5s',
    background: 'rgba(0, 0, 0, 0.65)',
  },
  'header-container': {
    width: 'calc(100% - 24px)',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '728px',
    },
    [theme.breakpoints.up('md')]: {
      width: '90%',
      maxWidth: '1440px',
    },
  },
  logo: {
    height: '35px',
    '& img': {
      height: '100%',
      width: 'unset',
    },
    [theme.breakpoints.up('md')]: {
      height: '50px',
    },
  },
  'navbar-desktop': {
    alignItems: 'center',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  'navbar-item': {
    padding: '10px',
    color: 'rgba(255, 255, 255, 0.6)',
    display: 'inline-block',
    fontSize: '18px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontFamily: 'Noto Sans',
  },
  'navbar-item-active': {
    color: 'white',
    fontWeight: 'bold',
  },
  'language-item': {
    color: 'rgba(255, 255, 255, 0.6)',
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    '& img': {
      width: '36px',
      height: '36px',
      marginRight: '8px',
    },
  },
  'current-language-arrow': {
    display: 'flex',
    transition: 'transform 0.3s',
    transform: 'rotate(180deg)',
  },
  'current-language-arrow-active': {
    transform: 'rotate(0deg)',
  },
  'language-list-container': {
    background: '#121212',
    border: '1px solid #2a2a2a',
  },
  'language-list': {
    padding: '0',
    '& li': {
      padding: '12px 20px',
    },
    '& li + li': {
      borderTop: '1px solid #2a2a2a',
    },
  },
  'navbar-mobile': {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  'navbar-mobile-btn': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    minWidth: 'unset',
    lineHeight: 'unset',
    '& span': {
      color: 'rgba(255, 255, 255, 0.6)',
    },
    '& svg': {
      width: '40px',
      height: '40px',
    },
  },
  'navbar-mobile-btn-active': {
    '& span': {
      color: 'white',
    },
  },
  'mobile-menu-backdrop': {
    backgroundColor: 'rgba(0, 0, 0, 1)',
  },
  'mobile-menu-wrap': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px 0 40px',
    position: 'absolute',
    top: '52px',
    left: '0',
    right: '0',
    bottom: '0',
  },
  'mobile-menu-group': {
    padding: '0 24px',
  },
  'mobile-menu-item': {
    display: 'block',
    padding: '16px 0',
    textAlign: 'center',
    '& + &': {
      borderTop: '2px solid rgba(255, 255, 255, 0.20)',
    },
  },
  'mobile-language-container': {
    display: 'flex',
    justifyContent: 'center',
  },
}));
