import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  'advantage-wrap': {
    padding: '88px 0 76px',
    position: 'relative',
    zIndex: '1',
    backgroundImage: `url(${require(`@/images/img/img_bg_our_service.webp`).default})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '9.8% 0 39%',
    },
    [theme.breakpoints.up('md')]: {
      padding: '9.8% 0',
      backgroundSize: '100% 100%',
    },
  },
  'advantage-title': {
    fontFamily: 'Oswald, sans-serif',
    fontSize: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '12px',
    lineHeight: 'normal',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: '48px',
      marginBottom: '42px',
    },
  },
  'advantage-container': {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '16px',
    position: 'relative',
    zIndex: '1',
    [theme.breakpoints.up('sm')]: {
      gap: '20px',
      paddingTop: '40px',
    },
  },
  'advantage-swiper-container': {
    padding: '0 15.8%',
    [theme.breakpoints.up('sm')]: {
      padding: '0 22.27%',
    },
  },
  'advantage-swiper': {
    paddingTop: '40px',
    paddingBottom: '40px',
    '& .swiper-pagination-bullet': {
      width: '12px',
      height: '12px',
      margin: '0 8px !important',
      background: '#565656',
      opacity: '1',
    },
    '& .swiper-pagination-bullet-active': {
      background: '#E0A737',
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '52px',
    },
  },
  'swiper-btn': {
    background: 'transparent',
    border: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    '& svg': {
      width: '24px',
      height: '24px',
      color: '#E0A737',
      [theme.breakpoints.up('sm')]: {
        width: '48px',
        height: '48px',
      },
    },
    '&.advantage-prev-btn': {
      left: '20px',
      [theme.breakpoints.up('sm')]: {
        left: '68px',
      },
    },
    '&.advantage-next-btn': {
      right: '20px',
      [theme.breakpoints.up('sm')]: {
        right: '68px',
      },
    },
  },
  'advantage-item': {
    // backgroundImage: `url(${require(`@/images/img/shield.webp`).default})`,
    // backgroundSize: '100% 100%',
    width: '250px',
    minHeight: '348px',
    // height: '240px',
    padding: '82px 20px 20px',
    borderRadius: '8px',
    border: '2px solid #E0A737',
    background: 'rgba(49, 49, 49, 0.50)',
    backdropFilter: 'blur(4px)',
    [theme.breakpoints.up('sm')]: {
      width: '289px',
      minHeight: '465px',
      padding: '120px 20px 20px',
      '&.id': {
        minHeight: '505px',
      },
    },
  },
  'advantage-item-icon': {
    width: '87px',
    height: '100px',
    margin: 'auto',
    backgroundImage: `url(${require(`@/images/img/service-item-bg.webp`).default})`,
    backgroundSize: '100% 100%',
    position: 'absolute',
    top: '-30px',
    left: '50%',
    transform: 'translateX(-50%)',
    '& img': {
      width: '58px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    [theme.breakpoints.up('sm')]: {
      width: '110px',
      height: '127px',
      top: '-40px',
      '& img': {
        width: '70px',
      },
    },
  },
  'advantage-item-title': {
    height: '66px',
    marginBottom: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Noto Sans',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: 'normal',
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      marginBottom: '16px',
    },
  },
  'advantage-item-content': {
    fontFamily: 'Noto Sans',
    fontSize: '16px',
    letterSpacing: '0.32px',
    fontWeight: '400',
    lineHeight: '140%',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      letterSpacing: '0.38px',
    },
  },
  mask: {
    width: '100%',
    height: '96px',
    position: 'absolute',
    left: '0',
    bottom: '0',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 50%, #000000 100%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'down-arrow': {
    width: '36px',
    animation: '$slide-down 1s infinite',
  },
  '@keyframes slide-down': {
    from: {
      transform: 'translateY(0)',
    },
    to: {
      transform: 'translateY(10px)',
    },
  },
}));
