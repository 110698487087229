export const navbarItems = ['home', 'about', 'advantage', 'demo', 'contact'];
export const languages = [
  {
    label: 'ENGLISH',
    value: 'en',
  },
  {
    label: '中文',
    value: 'cn',
  },
  {
    label: 'Tiếng Việt',
    value: 'vi',
  },
  {
    label: 'Indonesia',
    value: 'id',
  },
  {
    label: 'Русский',
    value: 'ru',
  },
  {
    label: '한국어(韓文)',
    value: 'kr',
  },
];

export const demoLinks = [
  'https://www.ws168fight.com/?token=O9k3Ev1YhHRUVr5f', // 英文
  'https://www.ws168fight.com/?token=4l493w4TPMSQHzt8', // 中文
  'https://www.ws168fight.com/?token=3oaeN3oCjHL4e22C', // 越南文
  'https://www.ws168fight.com/?token=VQP93KP8aUl0FZzu', // 印尼文
  'https://www.ws168fight.com/?token=2w0GZg4177dY0cgj', // 俄文
  'https://www.ws168fight.com/?token=fDciahmE6M032WlW', // 韓文
];
