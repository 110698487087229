import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Helmet } from 'react-helmet';
import { theme } from './config/theme';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '@/config/global.scss';
import Page from '@/page';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      // once: true,
    });
  }, []);

  return (
    <React.Suspense fallback={<></>}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ParallaxProvider>
          <Helmet>
            <link rel='preload' href={require(`@/images/img/img_bg_home.webp`).default} as='image' />
          </Helmet>
          <Page />
        </ParallaxProvider>
      </ThemeProvider>
    </React.Suspense>
  );
}

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
