import React, { useState, useRef, useMemo, useEffect } from 'react';
import cn from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash-es';
import { Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Backdrop } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import { navbarItems, languages } from '@/enum/common';
import { theme } from '@/config/theme';
import { scrollToSection } from '@/utils';
import { useStyles } from './headerStyle';

export default function Home() {
  const { i18n, t } = useTranslation();
  const cx = cn.bind(useStyles());
  const [isMobile, setIsMobile] = useState(false);
  const [activeNavbarItem, setActiveNavbarItem] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const [languageListOpen, setLanguageListOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrollHeader, setScrollHeader] = useState(false);
  const clickSection = useRef(null);
  const anchorRef = useRef(null);

  // return focus to the button when we transitioned from !LanguageListOpen -> LanguageListOpen
  const prevLanguageListOpen = useRef(languageListOpen);

  useEffect(() => {
    setIsMobile(window.innerWidth < theme.breakpoints.values['md']);

    const handleResize = () => {
      setIsMobile(window.innerWidth < theme.breakpoints.values['md']);
    };

    const getLanguage = () => {
      const localStorageLanguage = window.localStorage.getItem(`${window.location.hostname}-lng`);
      if (localStorageLanguage) {
        return languages.find((language) => language.value === localStorageLanguage);
      } else {
        return languages[0];
      }
    };
    setCurrentLanguage(getLanguage());

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      document.body.style.overflow = 'auto';
      setMobileMenuOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [mobileMenuOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById('header');
      const headerHeight = header ? header.offsetHeight : 0;
      const scrollY = window.scrollY;

      const newActiveNavbarItem = cloneDeep(navbarItems)
        .reverse()
        .find((item) => {
          const section = document.getElementById(item);
          const home = document.getElementById('home');
          const addHeight = section === home ? 0 : home.offsetHeight;
          const currentSectionContainerTop = document.getElementById(`${item}-container`)?.offsetTop ?? 0;

          return scrollY + headerHeight >= addHeight + section.offsetTop + currentSectionContainerTop;
        });

      if (newActiveNavbarItem === clickSection.current) {
        clickSection.current = null;
      }

      if (!clickSection.current) {
        setActiveNavbarItem(newActiveNavbarItem || navbarItems[0]);
      }

      if (scrollY >= 30) {
        setScrollHeader(true);
      } else {
        setScrollHeader(false);
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeNavbarItem]);

  useEffect(() => {
    if (prevLanguageListOpen.current === true && languageListOpen === false) {
      anchorRef.current.focus();
    }

    prevLanguageListOpen.current = languageListOpen;
  }, [languageListOpen]);

  const handleToggle = () => {
    setLanguageListOpen((prevLanguageListOpen) => !prevLanguageListOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setLanguageListOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setLanguageListOpen(false);
    }
  }

  const goToSection = (section) => {
    if (section === activeNavbarItem) return;
    scrollToSection(section);
    clickSection.current = section;
    setActiveNavbarItem(section);
  };

  const otherLanguages = useMemo(() => {
    if (!currentLanguage) {
      return languages;
    } else {
      return languages.filter((language) => language.value !== currentLanguage.value);
    }
  }, [currentLanguage]);

  const LanguageComponent = () => {
    return (
      <>
        {currentLanguage && (
          <Button
            className={cx('language-item')}
            ref={anchorRef}
            aria-controls={languageListOpen ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            onClick={handleToggle}>
            <img src={require(`@/images/flag/${currentLanguage.value}.svg`).default} alt={currentLanguage.value} />
            <span>{currentLanguage.label}</span>
            <span className={cx('current-language-arrow', { 'current-language-arrow-active': languageListOpen })}>
              <ExpandLessIcon />
            </span>
          </Button>
        )}
        <Popper open={languageListOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
              <Paper className={cx('language-list-container')}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    className={cx('language-list')}
                    autoFocusItem={languageListOpen}
                    id='menu-list-grow'
                    onKeyDown={handleListKeyDown}>
                    {otherLanguages.map((language, index) => {
                      return (
                        <MenuItem
                          key={index}
                          onClick={(event) => {
                            setCurrentLanguage(language);
                            i18n.changeLanguage(language.value);
                            handleClose(event);
                            // setMobileMenuOpen(false);
                          }}
                          className={cx('language-item')}>
                          <img src={require(`@/images/flag/${language.value}.svg`).default} alt={language.value} />
                          <span>{language.label}</span>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  };

  const NavbarItemsComponent = () => {
    return navbarItems.map((item, index) => {
      return (
        <a
          key={index}
          href={`#${item}`}
          className={cx('navbar-item', {
            'mobile-menu-item': isMobile,
            'navbar-item-active': activeNavbarItem === item,
          })}
          onClick={(e) => {
            e.preventDefault();
            goToSection(item);
            if (isMobile) setMobileMenuOpen(false);
          }}>
          {t(`title.${item}`)}
        </a>
      );
    });
  };

  return (
    <header className={cx('header', { 'header-active': scrollHeader })} id={'header'}>
      <div className={cx('header-container')}>
        <div className={cx('logo')}>
          <img src={require('@/images/logo/ws168.webp').default} alt='ws168' />
        </div>
        {!isMobile && (
          <nav className={cx('navbar-desktop')}>
            {NavbarItemsComponent()}
            <div className={cx('navbar-item')}>{LanguageComponent()}</div>
          </nav>
        )}
        {isMobile && (
          <nav className={cx('navbar-mobile')}>
            <Button
              className={cx('navbar-mobile-btn', { 'navbar-mobile-btn-active': mobileMenuOpen })}
              onClick={() => {
                setMobileMenuOpen(!mobileMenuOpen);
              }}>
              {mobileMenuOpen ? <ClearIcon /> : <MenuIcon />}
            </Button>
            <Backdrop className={cx('mobile-menu-backdrop')} open={mobileMenuOpen}>
              <div className={cx('mobile-menu-wrap')}>
                <div className={cx('mobile-menu-group')}>{NavbarItemsComponent()}</div>
                <div className={cx('mobile-language-container')}>{LanguageComponent()}</div>
              </div>
            </Backdrop>
          </nav>
        )}
      </div>
    </header>
  );
}
