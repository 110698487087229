import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  'gaming-wrap': {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0',
    },
  },
  'gaming-container': {
    background: `url(${require(`@/images/img/img_bg_home.webp`).default}) lightgray 50% / cover no-repeat`,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    [theme.breakpoints.up('sm')]: {
      // backgroundSize: '1920px',
      backgroundPosition: 'top',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      paddingBottom: '8.5%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  },
  'gaming-text-group': {
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.80))',
    position: 'absolute',
    top: '56%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: '2',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      top: '55.5%',
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      top: 'unset',
      left: 'unset',
      transform: 'unset',
    },
  },
  'gaming-placeholder': {
    textAlign: 'center',
    margin: 'auto',
    color: '#FFF84D',
    fontFamily: 'Oswald',
    fontSize: '20px',
    lineHeight: '14.347px' /* 100% */,
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: '30px',
      lineHeight: '100%' /* 100% */,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '36px',
      lineHeight: '36px' /* 100% */,
    },
  },
  'gaming-title': {
    margin: 'auto',
    marginBottom: '12px',
    maxWidth: '500px',
    textAlign: 'center',
    fontFamily: 'Oswald, Noto Sans',
    fontSize: '52px',
    fontWeight: 'bold',
    lineHeight: '60px',
    textTransform: 'uppercase',
    background: 'linear-gradient(182deg, #FFF 44.79%, #F5FF88 66.57%, #D59900 87.88%)',
    backgroundClip: 'text',
    color: 'transparent',
    [theme.breakpoints.up('sm')]: {
      width: '728px',
      maxWidth: 'unset',
      fontSize: '96px',
      lineHeight: '1.1',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '24px',
      width: '972px',
      fontSize: '132px',
      lineHeight: '1.15',
    },
  },
  'gaming-content': {
    width: '228px',
    margin: 'auto',
    textAlign: 'center',
    fontFamily: 'Roboto Condensed',
    fontSize: '16px',
    letterSpacing: '0.48px',
    lineHeight: 'normal',
    [theme.breakpoints.up('sm')]: {
      width: '439px',
      fontSize: '24px',
      letterSpacing: '0.72px',
    },
  },
}));
