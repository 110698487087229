import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  'about-wrap': {
    position: 'relative',
    overflow: 'hidden',
    padding: '0 20px 365px',
    backgroundImage: `url(${require(`@/images/img/img_bg_about.webp`).default})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    [theme.breakpoints.up('sm')]: {
      padding: '210px 0 246px',
      backgroundPosition: 'center',
    },
    [theme.breakpoints.up('md')]: {
      padding: '8% 0 9%',
    },
  },
  'about-container': {
    position: 'relative',
    zIndex: '1',
    [theme.breakpoints.up('sm')]: {
      width: '608px',
      margin: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      maxWidth: '1200px',
    },
  },
  'about-description-container': {
    marginBottom: '28px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '31px',
    },
    [theme.breakpoints.up('md')]: {
      width: '466px',
      marginBottom: '6%',
      marginLeft: '5.38%',
      paddingTop: '65px',
    },
  },
  'about-description-title': {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Oswald, sans-serif',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: 'normal',
    textTransform: 'uppercase',
    marginBottom: '8px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '48px',
      marginBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  'about-description-content': {
    width: '100%',
    fontSize: '16px',
    fontFamily: 'Not Sans',
    lineHeight: '140%',
    letterSpacing: '0.72px',
    '& + &': {
      marginTop: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      letterSpacing: '0.8px',
    },
  },
  'about-feature-group': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '10px 20px',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
      margin: 'auto',
      gap: '12px 20px',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      marginBottom: '54px',
      gap: '48px',
    },
  },
  'about-feature-item': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    textShadow: '2px 2px 4px #000',
  },
  'about-feature-main-text': {
    color: '#FFB800',
    fontFamily: 'Oswald',
    fontSize: '56px',
    fontWeight: '700',
    lineHeight: 'normal',
    [theme.breakpoints.up('sm')]: {
      fontSize: '80px',
    },
  },
  'about-feature-sub-text': {
    marginTop: '-10px',
    fontFamily: 'Noto Sans',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: 'normal',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
  },
  'about-cock-img': {
    position: 'absolute',
    width: '480px',
    right: '-144px',
    bottom: '-148px',
    [theme.breakpoints.up('sm')]: {
      width: '539px',
      right: '-92px',
      bottom: '-166px',
    },
    [theme.breakpoints.up('md')]: {
      width: '45.3125%',
      // left: 'calc(417px + 18.75%)',
      left: '40.47%',
      top: '7%',
      bottom: '0',
    },
  },
}));
