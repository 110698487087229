import React from 'react';
import cn from 'classnames/bind';
import { Parallax } from 'react-scroll-parallax';
import Header from '@/component/header/Header';
import Gaming from '@/component/gaming/Gaming';
import About from '@/component/about/About';
import Advantage from '@/component/advantage/Advantage';
import Demo from '@/component/demo/Demo';
import Contact from '@/component/contact/Contact';
import Footer from '@/component/footer/Footer';
import 'aos/dist/aos.css';
import { useStyles } from './style';

export default function Home() {
  const cx = cn.bind(useStyles());

  return (
    <div className={cx('home-wrap')}>
      <Header />

      {/* Main Content */}
      <main className={cx('main-wrap')}>
        <Gaming />
        <div className={cx('main-container')}>
          {/* <div className={cx('visual-img')}>
            <Parallax translateY={[0, -100]} startScroll={0} endScroll={2000} className={cx('main-parallax')}>
              <img src={require('@/images/img/visual.webp').default} alt='visual' />
            </Parallax>
          </div>
          <div className={cx('sub-visual-img')}>
            <Parallax translateY={[0, -100]} startScroll={0} endScroll={2000} className={cx('sub-parallax')}></Parallax>
          </div> */}
          <About />
          <Advantage />
          <Demo />
          <Contact />
          <Footer />
        </div>
      </main>
    </div>
  );
}
