import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames/bind';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Mask from '@/component/mask/Mask';
import { theme } from '@/config/theme';
import { navbarItems } from '@/enum/common';
import { scrollToSection } from '@/utils';
import { useStyles } from './advantageStyle';

import 'swiper/swiper.css'; // core Swiper
import 'swiper/modules/navigation.min.css'; // Navigation module
import 'swiper/modules/pagination.min.css'; // Pagination module

export default function Home() {
  const { t, i18n } = useTranslation();
  const cx = cn.bind(useStyles());
  const [isMobile, setIsMobile] = useState(window.innerWidth <= theme.breakpoints.values['lg']);
  // const [slidesPerView, setSlidesPerView] = useState(window.innerWidth > theme.breakpoints.values['md'] ? 2 : 1);
  const swiperRef = useRef(null);
  const advantageItems = Array.from({ length: 4 });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= theme.breakpoints.values['lg']);
      // setSlidesPerView(window.innerWidth > theme.breakpoints.values['md'] ? 2 : 1);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    /* Advantage Section */
    <section id={navbarItems[2]} className={cx('advantage-wrap')}>
      <h2
        data-aos='fade-up'
        className={cx('advantage-title', 'animate-fade-in-position')}
        id={`${navbarItems[2]}-container`}>
        {t('title.advantage')}
      </h2>
      {isMobile && (
        <div
          data-aos='fade-up'
          className={cx('advantage-container', 'advantage-swiper-container', 'animate-fade-in-position')}>
          <button className={cx('advantage-prev-btn', 'swiper-btn')} onClick={() => swiperRef.current.slidePrev()}>
            <ChevronLeftIcon />
          </button>
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            autoplay={true}
            // navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            className={cx('advantage-swiper')}>
            {advantageItems.map((item, index) => {
              return (
                <SwiperSlide key={index} className={cx('advantage-item')}>
                  <div className={cx('advantage-item-icon')}>
                    {/* {advantageItemsSVG(index)} */}
                    <img
                      src={require(`@/images/img/service-icon-${index + 1}.webp`).default}
                      alt={`service-icon-${index + 1}`}
                    />
                  </div>
                  <h3 className={cx('advantage-item-title')}>{t(`advantage.item_${index + 1}.title`)}</h3>
                  <p className={cx('advantage-item-content')}>{t(`advantage.item_${index + 1}.content`)}</p>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <button className={cx('advantage-next-btn', 'swiper-btn')} onClick={() => swiperRef.current.slideNext()}>
            <ChevronRightIcon />
          </button>
        </div>
      )}
      {!isMobile && (
        <div className={cx('advantage-container')}>
          {advantageItems.map((item, index) => {
            return (
              <div
                data-aos='fade-up'
                key={index}
                className={cx('advantage-item', 'animate-fade-in-position', i18n.language)}>
                <div className={cx('advantage-item-icon')}>
                  {/* <AdvantageItemsSVG /> */}
                  {/* {advantageItemsSVG(index)} */}
                  <img
                    src={require(`@/images/img/service-icon-${index + 1}.webp`).default}
                    alt={`service-icon-${index + 1}`}
                  />
                </div>
                <h3 className={cx('advantage-item-title')}>{t(`advantage.item_${index + 1}.title`)}</h3>
                <p className={cx('advantage-item-content')}>{t(`advantage.item_${index + 1}.content`)}</p>
              </div>
            );
          })}
        </div>
      )}
      <Mask goToSection={() => scrollToSection(navbarItems[3])} />
    </section>
  );
}
