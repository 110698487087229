import React from 'react';
import cn from 'classnames/bind';
import { useStyles } from './footerStyle';

export default function Home() {
  const cx = cn.bind(useStyles());

  return (
    <footer className={cx('footer')}>
      <p>Copyright @ 2024 WS168. All rights reserved.</p>
    </footer>
  );
}
