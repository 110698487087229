// import CryptoJS from 'crypto-js';

// export const decryptData = (key, iv, encryptStr) => {
//   const cipherKey = CryptoJS.enc.Utf8.parse(key);
//   const cipherIv = CryptoJS.enc.Utf8.parse(iv);
//   const reverseStr = encryptStr.split('').reverse().join('');
//   const decrypt = CryptoJS.AES.decrypt(reverseStr, cipherKey, { iv: cipherIv });
//   const decryptStr = decrypt.toString(CryptoJS.enc.Utf8);
//   const parse = JSON.parse(decryptStr);
//   return parse;
// };

// export const encryptData = (key, iv, data) => {
//   const cipherKey = CryptoJS.enc.Utf8.parse(key);
//   const cipherIv = CryptoJS.enc.Utf8.parse(iv);
//   const stringifyData = JSON.stringify(data);
//   const encrypt = CryptoJS.AES.encrypt(stringifyData, cipherKey, { iv: cipherIv });
//   const encryptStr = encrypt.toString();
//   const reverseStr = encryptStr.split('').reverse().join('');
//   return reverseStr;
// };

export const scrollToSection = (section) => {
  const currentSection = document.getElementById(section);
  const header = document.getElementById('header');
  const home = document.getElementById('home');

  if (currentSection && header && home) {
    const currentSectionContainerTop = document.getElementById(`${section}-container`)?.offsetTop ?? 0;
    const scrollTop =
      currentSection === home
        ? 0
        : currentSection.offsetTop + home.offsetHeight + currentSectionContainerTop - header.offsetHeight;
    window.scrollTo({
      behavior: 'smooth',
      top: scrollTop,
    });
  }
};
