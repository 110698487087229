import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  'home-wrap': {
    background: '#000',
    color: 'white',
  },
  'main-wrap': {
    marginTop: '-60px',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginTop: '-76px',
    },
  },
  'main-container': {
    background: 'linear-gradient(180deg, #000 0%, #333 49.5%, #000 100%);',
    position: 'relative',
    zIndex: '1',
    paddingTop: '100px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '0',
    },
  },
  'visual-img': {
    width: '100%',
    position: 'absolute',
    bottom: '100%',
    left: '0',
  },
  'sub-visual-img': {
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    '& img': {
      visibility: 'hidden',
    },
  },
  'sub-parallax': {
    background: '#000',
    height: '56.25vw',
    mixBlendMode: 'difference',
  },
}));
