import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    padding: '20px 0',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.6)',
    position: 'absolute',
    left: '0',
    bottom: '0',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
  },
}));
