import React from 'react';
import cn from 'classnames/bind';
import { useStyles } from './maskStyle';
import { ReactComponent as DownArrowSVG } from '@/images/img/arrow_down.svg';

export default function Mask({ goToSection }) {
  const cx = cn.bind(useStyles());

  return (
    <div className={cx('mask')}>
      <div className={cx('down-arrow')} onClick={goToSection}>
        <DownArrowSVG />
      </div>
    </div>
  );
}
