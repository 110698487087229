import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  'mask-top': {
    width: '100%',
    height: '100px',
    position: 'absolute',
    left: '0',
    top: '0',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 50%, #000000 100%)',
    [theme.breakpoints.up('sm')]: {
      height: '210px',
    },
  },
}));
