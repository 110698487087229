import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames/bind';
import MaskTop from '@/component/mask-top/MaskTop';
import Mask from '@/component/mask/Mask';
import { navbarItems } from '@/enum/common';
import { scrollToSection } from '@/utils';
import { useStyles } from './aboutStyle';

export default function About() {
  const { t } = useTranslation();
  const cx = cn.bind(useStyles());
  const aboutItems = Array.from({ length: 5 });

  return (
    /* About Section */
    <section className={cx('about-wrap')} id={navbarItems[1]}>
      <MaskTop />
      <div className={cx('about-container')} id={`${navbarItems[1]}-container`}>
        <div className={cx('about-description-container', 'animate-fade-in-position')}>
          <h2 data-aos='fade-up' className={cx('about-description-title', 'animate-fade-in-position')}>
            {t('title.about')}
          </h2>
          <p data-aos='fade-up' className={cx('about-description-content', 'animate-fade-in-position')}>
            {t('about.content_1')}
          </p>
          <p data-aos='fade-up' className={cx('about-description-content', 'animate-fade-in-position')}>
            {t('about.content_2')}
          </p>
        </div>
        <div className={cx('about-feature-group')}>
          {aboutItems.map((item, index) => {
            return (
              <div data-aos='fade-up' key={index} className={cx('about-feature-item', 'animate-fade-in-position')}>
                <p className={cx('about-feature-main-text')}>{t(`about.item_${index + 1}.main_text`)}</p>
                <p className={cx('about-feature-sub-text')}>{t(`about.item_${index + 1}.sub_text`)}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div data-aos='fade-left' className={cx('about-cock-img', 'animate-fade-left-position')}>
        <img src={require('@/images/img/img_about_cock.webp').default} alt='about cockfight' />
      </div>
      <Mask goToSection={() => scrollToSection(navbarItems[2])} />
    </section>
  );
}
