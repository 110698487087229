import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mask: {
    width: '100%',
    height: '56px',
    position: 'absolute',
    left: '0',
    bottom: '0',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 50%, #000000 100%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1',
    [theme.breakpoints.up('sm')]: {
      height: '96px',
    },
  },
  'down-arrow': {
    width: '20px',
    animation: '$slide-down 1s infinite',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      width: '36px',
    },
  },
  '@keyframes slide-down': {
    from: {
      transform: 'translateY(0)',
    },
    to: {
      transform: 'translateY(10px)',
    },
  },
}));
