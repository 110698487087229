import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import en from './en.json';
import cn from './zh-CN.json';
import vi from './vi.json';
import id from './id.json';
import ru from './ru.json';
import kr from './kr.json';

// const getLocale = (lang) => {
//   switch (lang) {
//     case 'cn':
//       return 'zh-CN';
//     case 'zh-TW':
//       return 'zh-TW';
//     default:
//       return lang;
//   }
// };

const resources = {
  en: {
    translation: en,
  },
  cn: {
    translation: cn,
  },
  vi: {
    translation: vi,
  },
  id: {
    translation: id,
  },
  ru: {
    translation: ru,
  },
  kr: {
    translation: kr,
  },
};

const option = {
  order: [
    'localStorage',
    // 'navigator'
  ],
  lookupLocalStorage: `${window.location.hostname}-lng`,
};
const languagedetector = new LanguageDetector(null, option);

// @ts-expect-error TS(2769): No overload matches this call.
i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .use(languagedetector) //偵測瀏覽器語言
  .init({
    fallbackLng: 'en', // 若當前語言沒有對應的翻譯則使用這個語言
    // lng: 'en', //預設語言
    resources, //引入字典檔
    interpolation: {
      // 是否要讓字詞 escaped 來防止 xss 攻擊，因為 React.js 已經做了，這裡設成 false
      escapeValue: false,
    },
    // react: {
    //   useSuspense: false, //   <---- this will do the magic
    // },
    // parse: (data) => data,
    // backend: {
    //   parse: (data) => data,
    //   async request(options, url, payload, cb) {
    //     // @ts-expect-error TS(2339): Property 'isLanguageChangingTo' does not exist on ... Remove this comment to see the full error message
    //     const { isLanguageChangingTo } = i18n;
    //     let locale = false;
    //     let errLocale = {};
    //     try {
    //       const localePkg = await import(`./${getLocale(isLanguageChangingTo)}.json`);

    //       locale = Object.assign(localePkg.default, errLocale);
    //     } catch (err) {
    //       // not exist
    //     }

    //     if (!locale) {
    //       return cb(null, {
    //         status: 404,
    //         data: null,
    //       });
    //     }

    //     cb(null, {
    //       status: 200,
    //       // @ts-expect-error TS(2322): Type 'boolean' is not assignable to type 'string'.
    //       data: locale,
    //     });
    //   },
    // },
  });

export default i18n;
